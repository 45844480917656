// == PWD WP Sass Framework ==
// Hero element styles
// Template: templates/hero.php

@use "sass:math";

@import "common/variables";
@import "common/tools";

.hero {
	&__overlay {
		padding-left: $grid-gutter-width * 0.5;
		padding-right: $grid-gutter-width * 0.5;
	}

	&__text-1 {
		padding-left: $grid-gutter-width * 0.5;
		font-weight: bold;
		line-height: math.div(44, 36);
		border-left: 4px solid #000000;
		$min_font: ($base-font-size + 2) * 1px;
		$max_font: 36px;
		@include fluid-type($base-container-min-width, map-get($container-max-widths, "xl"), $min_font, $max_font);
	}

	&__text-2 {
		border-left: 0;
	}
}

@media screen {
	.hero__text-1 {
		color: $theme-color-1;
		border-left-color: $theme-color-4;
	}
}

@media screen and (min-width: map-get($grid-breakpoints, sm)) {
	.hero {
		position: relative;

		&__picture {
			display: block;
			overflow-x: hidden;
			-ms-overflow-style: none;
		}

		&__image {
			margin-bottom: 0;
			max-width: none;
		}

		&__overlay {
			position: absolute;
			top: $grid-gutter-width * 0.5;
			bottom: $grid-gutter-width *0.5;
			left: 0;
			right: 0;
			padding-right: 0;
		}

		&__text-1 {
			width: 60%;
			max-width: 640px;
			margin-bottom: 0;
			padding: $grid-gutter-width * 0.5;
			align-self: flex-start;
			background-color: #ffffff;
		}

		&__text-2 {
			max-width: 526px;
			color: $white;
			width: 40%;
			background-color: $theme-color-1;
		}
	}
}

@media screen and (min-width: map-get($grid-breakpoints, md)) {
	.hero {
		&__text-1 {
			margin-bottom: 4%;
			padding: 45px 40px 45px 38px;
			align-self: flex-end;
		}

		&__text-2 {
			align-self: center;
			margin-bottom: 0;
		}
	}
}

@media screen and (min-width: map-get($grid-breakpoints, lg)) {
	.hero {
		&__text-1 {
			padding: 45px 40px 45px 38px;
		}
	}
}

@media screen and (min-width: map-get($container-max-widths, xl)) {
	.hero {
		&__text-1 {
			margin-left: 3%;
		}
	}
}
